import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Building = ({ pageContext }) => (
  <Layout pageContext={pageContext}>
    <SEO title="Малоэтажное строительство" />

    <section id="building" className="pb80 pt100 bg-dark uslugi building">

      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="section-title mb25">
              <h1>Малоэтажное строительство</h1>
              <p>Малоэтажное строительство включает в себя возведение коттеджей, особняков, домов и иных строений, высотой максимум 3 этажа. Сегодня этот вид работ перестал быть дорогостоящим. Этому поспособствовало применение инновационных материалов и технологий. ООО «СК МАСТЕР» предлагает услуги по малоэтажному строительству в Москве и Московской области. Мы работаем на рынке с 2013 года. За это время нами было реализовано множество проектов, ознакомиться с которыми можно в соответствующем разделе сайта.</p>
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-sm-12">
              <h2 className="color-style2">
              Строительство «под ключ» 
              </h2>
              <p>Компания «СК МАСТЕР» занимается строительством домов в Москве и Московской области по доступным ценам. В рамках заказа мы выполняем:</p>
              <ul className="list-group checklist">
                <li>земляные работы. Подготовим участок к строительству;</li>
                <li>фасадные работы. Создадим «лицо» вашего офиса или дома, которым можно будет гордиться;</li>
                <li>кровельные работы. Обеспечим надежную защиту дома от погодных явлений и при этом позволим сэкономить без ущерба качеству;</li>
                <li>устройство перекрытий, перегородок, фундаментов. Создадим долговечные и надежные конструкции в полном соответствии с требованиями СНиПов и ГОСТов;</li>
                <li>установку ворот и ограждающих конструкций и т.д.;</li>
              </ul>
              <p>
              По желанию заказчика дополнительно наши специалисты осуществят благоустройство прилегающей территории, устройство инженерных сетей, отделочные работы и многое другое. 
              </p>
          </div>
          <div className="col-md-12 col-sm-12">
              <h2 className="color-style2">«Плюсы» работы с «СК МАСТЕР»</h2>
              <p>Обращаясь в нашу компанию, Вы получаете:</p>
              <ul className="list-group checklist">
                <li>доступные цены. Мы закупаем материалы у проверенных поставщиков, используем современное энергоэффективное оборудование и технологии, имеем большой опыт работы, благодаря чему нам удалось сократить себестоимость работ до минимума. Это значит, что, работая с нами, Вы получите «премиум»-качество по доступной цене;</li>
                <li>надежность и гарантии. Мы официальная компания, которая дорожит своей репутацией. Все наши работы производятся в полном соответствии с требованиями нормативной документации и условиями договора;</li>
                <li>соблюдение сроков. За все время работы мы ни разу не заставляли наших клиентов ждать дольше, чем указано в договоре.</li>
              </ul>
              <p>
                Оцените преимущества сотрудничества с нами и Вы. Для этого свяжитесь с нашими менеджерами любым указанным на сайте способом.
              </p>
          </div>
        </div>
        <p className='exclamation'>
          «СК МАСТЕР» - верное решение Ваших строительных задач!
        </p>
      </div>
    </section>

  </Layout>
)

export default Building
